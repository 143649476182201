import {
  Banner,
  FlatCard,
  Helmet,
  IconArrowRight,
  IconChange,
  IconEvidence,
  IconListening,
  IconSupport,
  Interfaces,
  Layout,
  Link,
  PreStyledComponents,
  Theme,
  Variables,
  routesObject
} from "@life-without-barriers/react-components"
import styled, { ThemeProps, withTheme } from "styled-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { Container, IconWrapper } = PreStyledComponents
const { black } = Variables
const { youthFull } = Theme

interface SectionProps {
  icon: string
  title?: string
  to?: string
  children: React.ReactNode
}

interface StyledSectionLinkProps extends Interfaces.ThemedProps {
  hoverBGColor?: string
  hoverColor?: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledSectionLink = styled(({ hoverBGColor, hoverColor, ...rest }) => (
  <Link {...rest} />
))`
  color: ${black};
  &:hover,
  &:focus {
    color: ${({ hoverColor }: StyledSectionLinkProps) =>
      hoverColor ? hoverColor : black};

    .Section-container {
      background-color: ${({ hoverBGColor }: StyledSectionLinkProps) =>
        hoverBGColor ? hoverBGColor : "inherit"};
    }

    .Section-copy {
      color: ${({ hoverColor }: StyledSectionLinkProps) =>
        hoverColor ? hoverColor : black};
    }
  }

  .Section-container {
    transition: background-color 200ms ease-in;
  }

  .Section-copy,
  .Section-title,
  .Section-readmore {
    transition: color 200ms ease-in;
  }

  .Section-readmore path {
    transition: fill 200ms ease-in;
  }

  &:hover .Section-title,
  &:focus .Section-title,
  &:hover .Section-readmore {
    color: ${({ theme, hoverColor }: StyledSectionLinkProps) =>
      hoverColor ? hoverColor : theme.dark};
  }

  &:hover,
  &:focus {
    .Section-title,
    .Section-readmore {
      color: ${({ theme, hoverColor }: StyledSectionLinkProps) =>
        hoverColor ? hoverColor : theme.dark};
    }

    .Section-readmore path {
      fill: ${({ theme, hoverColor }: StyledSectionLinkProps) =>
        hoverColor ? hoverColor : theme.dark};
    }
  }
`

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "support") {
    return <IconSupport color={color} height={height} />
  }
  if (type === "change") {
    return <IconChange color={color} height={height} />
  }
  if (type === "evidence") {
    return <IconEvidence color={color} height={height} />
  }
  if (type === "listening") {
    return <IconListening color={color} height={height} />
  }
  return undefined
}

const Section = withTheme(
  ({
    theme,
    icon,
    title,
    to,
    children
  }: SectionProps & ThemeProps<Interfaces.Theme>) => {
    const SectionContents = (
      <div className="Section-container w-100 flex-ns bt bw1 b-lwb-grey-xxx-light pv4 tc tl-ns">
        <div className="w-100 w-10-ns w-20-m flex-shrink-0">
          <IconWrapper size="125" color={theme.xxxlight}>
            {iconSelector(icon, theme.medium, "58")}
          </IconWrapper>
        </div>
        <div className="w-80-m w-90-l pt4 pt0-ns pl4-ns pl5-l">
          {title && <h3 className="fw8 ts-display-4 Section-title">{title}</h3>}
          {children}
          {to && (
            <div className="fw6 mv3 flex items-baseline justify-center justify-start-ns color-lwb-theme-medium Section-readmore">
              Read more
              <IconArrowRight
                height="8"
                width="5"
                color={theme.medium}
                className="ml2"
              />
            </div>
          )}
        </div>
      </div>
    )

    return to ? (
      <StyledSectionLink className="no-underline normal" to={to}>
        {SectionContents}
      </StyledSectionLink>
    ) : (
      <React.Fragment>{SectionContents}</React.Fragment>
    )
  }
)

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const breadcrumbItems = [
  routesObject.home,
  {
    to: "/our-approach",
    text: "Our approach",
    title: "Return to parent page"
  }
]

const ChildYouAndFamilyPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Our approach - Child, youth and family | ${siteMetadata.title}`}
        description="We developed an evidence informed, therapeutic model of care to support the best possible outcomes for children and young people in out-of-home care."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout theme={youthFull}>
        <Banner
          title="Our approach - Child, youth and family"
          subTitle="To support the best possible outcomes for children and young
              people in out of home care, we are implementing an evidence
              informed, therapeutic model of care that is grounded by the six
              principles of CARE."
          image={bannerImage}
          breadcrumbs={breadcrumbItems}
        />
        <Container className="ph3 ph0-ns pv4 pv5-ns">
          <h2 className="fw8 ts-display-3 color-lwb-theme-medium tc pv3-ns mb4">
            Changing Lives, New Directions in Care
          </h2>
          <Section
            title="We Put Children First"
            icon="support"
            to="/our-approach/child-youth-and-family/we-put-children-first/"
          >
            <p>
              Every day, Life Without Barriers offers support and care to
              children. We want every one of those children to feel as safe and
              respected as they should. We all have a responsibility to make
              sure their safety comes first – that they’re well cared for,
              protected from abuse and given the respect they deserve.
            </p>
          </Section>
          <Section
            title="We create conditions for positive change &mdash; the CARE model"
            icon="change"
            to="/our-approach/child-youth-and-family/care-creating-conditions-for-change/"
          >
            <p>
              To support the best possible outcomes for children in out-of-home
              care, the six trauma informed CARE principles guide our work with
              children, young people and families.
            </p>
          </Section>
          <Section icon="evidence">
            <h3 className="fw8 ts-display-4 Section-title">
              When caring for children, young people and their families we work
              in ways that{" "}
              <Link
                rooted
                to="/about-us/publications-and-resources/evidence-informed-strategy"
                className="black fw8"
              >
                evidence tells us
              </Link>{" "}
              makes a difference for them
            </h3>
            <div className="flex flex-wrap mt4 tl">
              <div className="w-100 w-50-ns mt0 mt3-ns">
                <FlatCard
                  className="mr2-ns"
                  title="Therapeutic Crisis Intervention (TCI)"
                  body="This crisis prevention and intervention model teaches staff and carers how to help children learn constructive ways to handle crisis."
                  to="/our-approach/child-youth-and-family/tci-therapeutic-crisis-intervention/"
                />
              </div>
              <div className="w-100 w-50-ns mt3">
                <FlatCard
                  className="ml2-ns"
                  title="Multisystemic Therapy (MST)"
                  body="This home and community-based intervention program is for at-risk youth, their families and communities. Life Without Barriers is the MST Network Partner for Australia and New Zealand."
                  to="/services/child-youth-and-family/youth-justice/"
                  rooted
                />
              </div>
              <div className="w-100 w-50-ns mt3">
                <FlatCard
                  className="mr2-ns"
                  title="Family involved"
                  body="Our commitment to meaningful and ongoing family involvement aims to make sure children don’t lose their first and most enduring relationship."
                  to="/our-approach/child-youth-and-family/family-involved/"
                />
              </div>
              <div className="w-100 w-50-ns mt3">
                <FlatCard
                  className="ml2-ns"
                  title="MOCKINGBIRD FAMILY&trade;"
                  body="By forming a local care community MOCKINGBIRD FAMILY&trade; improves the sense of connection and wellbeing of children, young people and their carers."
                  to="/our-approach/child-youth-and-family/mockingbird-family/"
                />
              </div>
            </div>
          </Section>
          <Section
            title="We listen to the voices of children, family and carers"
            icon="listening"
          >
            <div className="flex flex-wrap mt4 tl">
              <div className="w-100 w-33-l mt0 mt3-ns">
                <FlatCard
                  title="Listening to children"
                  body="Children and young people have the right to have their voices heard."
                  to="/our-approach/child-youth-and-family/listening-to-children/"
                />
              </div>
              <div className="w-100 w-33-l mt3">
                <FlatCard
                  title="Listening to families"
                  body="We listen and learn from families to support the best possible outcomes for children and young people in out of home care."
                  className="ml3-l"
                  to="/our-approach/child-youth-and-family/listening-to-families/"
                />
              </div>
              <div className="w-100 w-33-l mt3">
                <FlatCard
                  title="Listening to carers"
                  body="We listen to carers and local care teams to understand lived experiences."
                  className="ml3-l"
                  to="/our-approach/child-youth-and-family/listening-to-carers/"
                />
              </div>
            </div>
          </Section>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(
      relativePath: { regex: "/img-our-approach-banner.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`

export default ChildYouAndFamilyPage
